import React, {useState} from 'react'
import { Container, Row, Col, Navbar, Nav } from 'react-bootstrap'
import logo from '../../assets/logo.png'
import './Header.css'
import { useSpring, animated } from 'react-spring'
import {HashLink} from "react-router-hash-link"


export default function Header() {

  // Styling
  let headerBackgroundColor = '#fff';
  const [scrolled, setScrolled] = useState('');

  // Scroll Position Effects
  React.useEffect(() => {
    window.addEventListener('scroll', () => {
      if (window.scrollY > 50) {
        setScrolled('shadow-drop')
      } else {
        setScrolled('')
      }
    })
  })

  // Animations
  const textAnim = useSpring({ 
    to: { opacity: 1, transform: `translateY(0px)` }, 
    from: { opacity: 0, transform: `translateY(-50px)` },
  })

  const logoAnim = useSpring({ 
    to: { opacity: 1 }, 
    from: { opacity: 0 },
    delay: 100
  })

  const [expanded, setExpanded] = useState(false);


  return (
    <Container fluid className='p-0 NavigationBar'>
      <Navbar className={scrolled} style={{background: headerBackgroundColor}} 
      fixed='top' expand="lg" expanded={expanded}>
        <Container>
          <animated.span style={logoAnim}>
            <Navbar.Brand>
              {/* <img src={logo} alt='logo' className='logo'></img> */}
              <span>Startec</span> <span>Computers</span>
            </Navbar.Brand>
          </animated.span>
          <Navbar.Toggle aria-controls="responsive-navbar-nav"
          onClick={() => {
            if (window.innerWidth < 992) {
              setExpanded(expanded ? false : true)
            }
          }}/>
          <Navbar.Collapse className='justify-content-end'>
            <animated.span style={textAnim}>
              <Nav className='me-auto justify-content-end' onClick={() => {
                if (window.innerWidth < 992) {
                  setExpanded(expanded ? false : true)
                }
              }}>
                <HashLink to='#home'>Home</HashLink>
                <HashLink to='#why-choose-us'>About Us</HashLink>
                <HashLink to='#services'>Services</HashLink>
                <HashLink to='#testimonials'>Testimonials</HashLink>
              </Nav>
            </animated.span>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </Container>
  )
}
