import React from 'react';
import ReactDOM from 'react-dom/client';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import App from './App';
import QRLanding from 'components/QRLanding/QRLanding';
import { HashRouter, Routes, Route } from "react-router-dom";


const root = ReactDOM.createRoot(document.getElementById('root'));

window.addEventListener("hashchange", (event) => {
  console.log(event)
});


root.render(
  <HashRouter basename='/'>
    <Routes>
      <Route path="*" element={<App />}></Route>
      <Route path="/qr" element={<QRLanding />}></Route>
    </Routes>
  </HashRouter>
);
