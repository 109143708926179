import React, { useEffect, useRef } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import './WhyUs.css'
import StyledImage from 'components/utils/StyledImage'
import img1 from 'assets/images/1.jpg'
import img2 from 'assets/images/2.jpg'
import img3 from 'assets/images/3.jpg'
import { useSpring, animated } from 'react-spring'
import { Cash, ClockFill, CpuFill, Shop } from 'react-bootstrap-icons'
import { useInView } from 'react-intersection-observer'

export default function About() {

  const { ref: titleRef, inView: isTitleVisible } = useInView({
    threshold: 1,
    triggerOnce: true
  });
  const { ref: blockRef, inView: isBlockVisible } = useInView({
    threshold: 0.1,
    triggerOnce: true
  });
  const { ref: eleBlockRef, inView: isEleBlockVisible } = useInView({
    threshold: 0.1,
    triggerOnce: true
  });

  const textAnim = useSpring({ 
    to: { opacity: isTitleVisible ? 1 : 0, transform: isTitleVisible ? 'scale(1)' : 'scale(0.8)' }, 
    from: { opacity: 0, transform: 'scale(0.8)' },
    delay: 300
  })

  const fadeInAnim = useSpring({ 
    to: { opacity: 1  }, 
    from: { opacity: 0},
    delay: 300
  })
  const textSlideLeftAnim = useSpring({ 
    to: { opacity: isEleBlockVisible ? 1 : 0, transform: isEleBlockVisible ? 'translateX(0px)' : 'translateX(-50px)' }, 
    from: { opacity: 0, transform: 'translateX(-50px)' },
    delay: 300
  })
  const textSlideDownAnim = useSpring({ 
    to: { opacity: isEleBlockVisible ? 1 : 0, transform: isEleBlockVisible ? 'translateY(0px)' : 'translateY(-50px)' }, 
    from: { opacity: 0, transform: 'translateY(-50px)' },
    delay: 500
  })

  const blockSlideDownAnim = useSpring({
    to: {opacity: isBlockVisible ? 1 : 0, transform: isBlockVisible ? 'translateY(0px)' : 'translateY(-50px)'},
    from: {opacity: 0, transform: 'translateY(-50px)'}
  })
  
  return (
    <Container>
      <Row className='row-titles'>
        <animated.h4 style={textAnim} ref={titleRef}>Two Decades of Expierence and Counting</animated.h4>
        <animated.h2 style={textAnim}>WHY CHOOSE US?</animated.h2>
      </Row>
      <Row>
        <animated.div className='why-us-container' ref={blockRef} style={blockSlideDownAnim}>
          <Row ref={eleBlockRef}>
            <Col md={6}>
              <div className='why-us-block'>
                <div className='why-us-icon-title-block'>
                  <animated.i className='why-us-icon-block' style={fadeInAnim}>
                    <ClockFill fontSize={40} color={'var(--color-lblue)'}/>
                  </animated.i>
                  <animated.h3 style={textSlideLeftAnim} className='why-us-text-title'><span style={{color:"var(--color-red)"}}>Fast</span> Turnaround Time</animated.h3>
                </div>
                <div className='why-us-text-block'>
                  <animated.span className='why-us-text-body' style={textSlideDownAnim}>
                    At Startec Computers, we work hard to save our clients time and money, thats why we complete the repairs on time as promised.
                  </animated.span>
                </div>
              </div>
            </Col>
            <Col md={6}>
              <div className='why-us-block'>
                  <div className='why-us-icon-title-block'>
                    <animated.i className='why-us-icon-block' style={fadeInAnim}>
                      <Cash fontSize={40} color={'var(--color-lblue)'}/>
                    </animated.i>
                    <animated.h3 style={textSlideLeftAnim} className='why-us-text-title'><span style={{color:"var(--color-red)"}}>Lowest</span> Price Guaranteed</animated.h3>
                  </div>
                  <div className='why-us-text-block'>
                    <animated.span className='why-us-text-body' style={textSlideDownAnim}>
                    Repairing beats replacing! We have the lowest prices in the market. Striving to become the best affordable computer repair shop.
                    </animated.span>
                  </div>
                </div>
            </Col>
            <Col md={6}>
              <div className='why-us-block'>
                    <div className='why-us-icon-title-block'>
                      <animated.i className='why-us-icon-block' style={fadeInAnim}>
                        <Shop fontSize={40} color={'var(--color-lblue)'}/>
                      </animated.i>
                      <animated.h3 style={textSlideLeftAnim} className='why-us-text-title'><span style={{color:"var(--color-red)"}}>Walk-In</span> Service</animated.h3>
                    </div>
                    <div className='why-us-text-block'>
                      <animated.span className='why-us-text-body' style={textSlideDownAnim}>
                        Bring your computer in and let us help you, no appointment needed. We offer same day service.
                      </animated.span>
                    </div>
                  </div>
            </Col>
            <Col md={6}>
              <div className='why-us-block'>
                <div className='why-us-icon-title-block'>
                  <animated.i className='why-us-icon-block' style={fadeInAnim}>
                    <CpuFill fontSize={40} color={'var(--color-lblue)'}/>
                  </animated.i>
                  <animated.h3 style={textSlideLeftAnim} className='why-us-text-title'><span style={{color:"var(--color-red)"}}>Genuine</span> Parts</animated.h3>
                </div>
                <div className='why-us-text-block'>
                  <animated.span className='why-us-text-body' style={textSlideDownAnim}>
                    Genuine parts carefully chosen for their quality and reliability from different brands. Do not fall for non-genuine parts elsewhere!
                  </animated.span>
                </div>
              </div>
            </Col>
          </Row>
        </animated.div>
      </Row>
    </Container>
  )
}
