import React, { useEffect, useState } from 'react'
import './ReviewBlock.css'
import { Container, Row, Col } from 'react-bootstrap'
import { useSpring, animated } from 'react-spring'
import { PersonFill, StarFill } from 'react-bootstrap-icons'

export default function ReviewBlock(props) {

  const [viewMore, setViewMore] = useState(false)

  let name = props.name
  let [statement, setStatment] = useState("")
  let url = props.url

  const returnSplit = (statement) => {
    let a = statement.split(" ")
    let b = ""
    for (let i = 0; i < 30; i++) b += " " + a[i]
    return b
  }

  useEffect(() => {
    if (props.statement.split(" ").length > 30) {
      setStatment(returnSplit(props.statement));
      setViewMore(true);
    } else {
      setStatment(props.statement)
    }
  },[])

  return (
    <Col xs={12} md={12} lg={6} xl={6} xxl={4} className='review-card-col'>
      <animated.div className='review-card-container' style={props.textSlideDownAnim}>
        <div className='review-card-header'>
          <span className='review-card-logo'>
            <PersonFill></PersonFill>
          </span>
          <span className='review-card-name'>
            {name}
          </span>
        </div>
        <div className='review-card-body'>
          <span className='review-card-statement'>
            {statement}
            { viewMore &&
              <span>
                &nbsp;- <a href={url} target='_blank'>Read more.</a>
              </span>
            }
          </span>
        </div>
        <div className='review-card-footer'>
          <span className='review-card-stars'>
            <StarFill></StarFill>
            <StarFill></StarFill>
            <StarFill></StarFill>
            <StarFill></StarFill>
            <StarFill></StarFill>
          </span>
        </div>
      </animated.div>
    </Col>
  )
}
