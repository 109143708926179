export const GoogleReviews = [
  {
    name: "Leda Clark",
    statement: "My life is on my computer, so I can say without joking that i 100% trust this man with my life. Leo is not only a technological wizard, he's extremely hardworking, honest, genuinely cares about doing what's best for the customer rather than what will get him the most money, and is absolutely one of the most polite people I've ever met. Outstanding customer service on absolutely all fronts. Startec is my first stop anytime I ever have a computer issue now, because I know I can be confident that I'll get the truth and top-notch work from this business. Couldn't be happier my friend recommended me here!!",
    url: "https://g.co/kgs/QTP9Pm"
  },
  {
    name: "Cody Davis",
    statement: "Leo is the absolute best. He got my pc done same day I brought it in. Not only did he fix all the issues I had but he cleaned it all very thoroughly before he called me to come get it. 10/10 customer service and will be a loyal customer forever. Would recommend Startec Computers to everyone.",
    url: "https://g.co/kgs/HN9LJs"
  },
  {
    name: "Adam McHenry",
    statement: "There isn't enough I could say about how great Leo is. He is nice, reliable, and honest. So many places try to up-sell you, but Leo truly wants whats best for the customer. He is very forthcoming with all the options you have, not just the ones that will benefit him the most. Anytime I, or anyone I know has a computer issue that needs to be fixed, Leo is the first stop. To sum it, just like you have a car guy that you trust 100%, Leo should be your computer guy that you trust 100%",
    url: "https://g.co/kgs/rdNi6Y"
  },
  {
    name: "Silvana Dinevski",
    statement: "Cannot say enough about Leo. Professional, honest, respectful and has patience. Took my laptop in and it was repaired the same day also I was charged a very very reasonable price. I would highly recommend Leo, if you want honesty! Happy Customer 😊",
    url: "https://g.co/kgs/nCs5Uw"
  },
  {
    name: "Kingsley Sears",
    statement: "Leo has been helpful beyond the call of duty. He has guided us through a series of problems with his knowledge and skill.l I would recommend Leo and Startec to anyone needing expert computer guidance. We now have the computer equipment we need.",
    url: "https://g.co/kgs/ncYyYX"
  },
  {
    name: "Victoria Owen",
    statement: "Leo built a desktop for me in January.  He asked detailed questions about what I wanted and informed me what was possible within my budget.  When I picked up the desktop, he spent a great deal of time pointing out the newer technology and how to effectively use it.  My favorite tip is how to disable my internet connection with a few clicks instead of unplugging my CAT6 cable each time I step away!    I am now the proud owner of a lightning-fast desktop.  Leo graciously answered questions about the desktop once I got it home. He takes pride in building computers with quality parts that will last.  A quality product, a quality build, and excellent customer service.  Leo at Startec is a great choice!",
    url: "https://g.co/kgs/pynQxy"
  },
  {
    name: "Ethan X",
    statement: "Purchased a printer cable earlier this year from Leo. It saved my life! Leo is very knowledgeable and helpful. I'm so glad we have this store in the neighborhood.",
    url: "https://g.co/kgs/d7M7JH"
  },
  {
    name: "Tyler Malinovich",
    statement: "Incredibly trustworthy business. Leo was very straightforward about the problems I had with my laptop and told me it wouldn’t even be worth my time paying for a repair. Would recommend to anyone looking for an honest business.",
    url: "https://g.co/kgs/HCPMx7"
  },
  {
    name: "Emily LaBrash",
    statement: "Words can describe how happy i am with the service i got here! When i first walked in with my PC, Leo took it in no problem and took my number and looked at my pc as soon as he could. Everything was fixed and at a great price and very quick compared to other big box places. He was attentive to my concerns and problems and made sure i was pleased with the decisions he made with my computer. Im taking any computer i have here and im recommending this place to everyone! Thank you thank you thank you!",
    url: "https://g.co/kgs/4eaYRp"
  },
]