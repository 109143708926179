import React, {useState} from 'react'
import './QRLanding.css'

export default function QRLanding() {
  return (
    <div className='placeholder_container'>
        <img className="placeholder_loader" src="placeholder.webp" />
    </div>
  )
}
