import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import './Services.css'
import { useSpring, animated } from 'react-spring'
import { useInView } from 'react-intersection-observer'

import { PcDisplay, Laptop, Motherboard, FolderCheck, ShieldCheck, Boxes, ArrowRepeat, ArrowLeftRight, Spellcheck, LaptopFill, OpticalAudio, AspectRatio, Keyboard, Cpu, Wifi, Terminal, Water, Printer, Speedometer, WrenchAdjustableCircle, WrenchAdjustable, WindowStack} from 'react-bootstrap-icons'

export default function Services() {
  const { ref: serviceContainer, inView: isServiceContainerVisible } = useInView({
    threshold: 1,
    triggerOnce: true
  });

  const { ref: titleRef, inView: isTitleVisible } = useInView({
    threshold: 1,
    triggerOnce: true
  });

  const { ref: contactText, inView: isContactTextVisible } = useInView({
    threshold: 1,
    triggerOnce: true
  });

  const slideAnim = useSpring({ 
    to: { opacity: isTitleVisible ? 1 : 0 }, 
    from: { opacity: 0},
    delay: 400
  })

  const textAnim = useSpring({ 
    to: { opacity: isTitleVisible ? 1 : 0, transform: isTitleVisible ? 'scale(1)' : 'scale(0.8)' }, 
    from: { opacity: 0, transform: 'scale(0.8)' }
  })

  const textSlideDownAnim = useSpring({ 
    to: { opacity: isTitleVisible ? 1 : 0, transform: isTitleVisible ? 'translateY(0px)' : 'translateY(-50px)' }, 
    from: { opacity: 0, transform: 'translateY(-50px)' },
    delay: 500
  })
  return (
    <Container>
      <Row>
        <span className='services-title'>
          <animated.h2 style={textAnim}>WHAT WE SERVICE</animated.h2>
          <animated.h5 style={textAnim}>We service both PC and Mac. Any brand! Any problem!</animated.h5>
        </span>
      </Row>
      <span ref={titleRef}></span>
      <Row>
        <animated.span className='services-container' style={slideAnim}>
          <span sm={6} md={3}>
            <animated.span className='services-choices'>
              <span className='services-block'>
                <span className='services-icon-block'>
                  <PcDisplay fontSize={24}/>
                </span>
                <span className='services-title'>
                  PC Repair
                </span>
                <span></span>
              </span>
            </animated.span>
          </span>
          <span sm={6} md={3}>
            <span className='services-choices'>
              <span className='services-block'>
                <span className='services-icon-block'>
                  <Laptop fontSize={24}/>
                </span>
                <span className='services-title'>
                  Laptop Repair
                </span>
                <span></span>
              </span>
            </span>
          </span>
          <span sm={6} md={3}>
            <span className='services-choices'>
              <span className='services-block'>
                <span className='services-icon-block'>
                  <ShieldCheck fontSize={24}/>
                </span>
                <span className='services-title'>
                  Virus Removal
                </span>
                <span></span>
              </span>
            </span>
          </span>
          <span sm={6} md={3}>
            <span className='services-choices'>
              <span className='services-block'>
                <span className='services-icon-block'>
                  <Boxes fontSize={24}/>
                </span>
                <span className='services-title'>
                  Data Backup
                </span>
                <span></span>
              </span>
            </span>
          </span>
          <span sm={6} md={3}>
            <span className='services-choices'>
              <span className='services-block'>
                <span className='services-icon-block'>
                  <ArrowRepeat fontSize={24}/>
                </span>
                <span className='services-title'>
                  Data Recovery
                </span>
                <span></span>
              </span>
            </span>
          </span>
          <span sm={6} md={3}>
            <span className='services-choices'>
              <span className='services-block'>
                <span className='services-icon-block'>
                  <ArrowLeftRight fontSize={24}/>
                </span>
                <span className='services-title'>
                  Data Transfer
                </span>
                <span></span>
              </span>
            </span>
          </span>
          <span sm={6} md={3}>
            <span className='services-choices'>
              <span className='services-block'>
                <span className='services-icon-block'>
                  <Spellcheck fontSize={24}/>
                </span>
                <span className='services-title'>
                  Windows Password Reset
                </span>
                <span></span>
              </span>
            </span>
          </span>
          <span sm={6} md={3}>
            <span className='services-choices'>
              <span className='services-block'>
                <span className='services-icon-block'>
                  <LaptopFill fontSize={24}/>
                </span>
                <span className='services-title'>
                  Laptop Screen Replacement
                </span>
                <span></span>
              </span>
            </span>
          </span>
          <span sm={6} md={3}>
            <span className='services-choices'>
              <span className='services-block'>
                <span className='services-icon-block'>
                  <OpticalAudio fontSize={24}/>
                </span>
                <span className='services-title'>
                  DC Jack Repair
                </span>
                <span></span>
              </span>
            </span>
          </span>
          <span sm={6} md={3}>
            <span className='services-choices'>
              <span className='services-block'>
                <span className='services-icon-block'>
                  <AspectRatio fontSize={24}/>
                </span>
                <span className='services-title'>
                  Touchpad Replacement
                </span>
                <span></span>
              </span>
            </span>
          </span>
          <span sm={6} md={3}>
            <span className='services-choices'>
              <span className='services-block'>
                <span className='services-icon-block'>
                  <Keyboard fontSize={24}/>
                </span>
                <span className='services-title'>
                  Keyboard Replacement
                </span>
                <span></span>
              </span>
            </span>
          </span>
          <span sm={6} md={3}>
            <span className='services-choices'>
              <span className='services-block'>
                <span className='services-icon-block'>
                  <Motherboard fontSize={24}/>
                </span>
                <span className='services-title'>
                  Motherboard Replacement
                </span>
                <span></span>
              </span>
            </span>
          </span>
          <span sm={6} md={3}>
            <span className='services-choices'>
              <span className='services-block'>
                <span className='services-icon-block'>
                  <Cpu fontSize={24}/>
                </span>
                <span className='services-title'>
                  Hardware
                </span>
                <span></span>
              </span>
            </span>
          </span>
          <span sm={6} md={3}>
            <span className='services-choices'>
              <span className='services-block'>
                <span className='services-icon-block'>
                  <Wifi fontSize={24}/>
                </span>
                <span className='services-title'>
                  Network Setup
                </span>
                <span></span>
              </span>
            </span>
          </span>
          <span sm={6} md={3}>
            <span className='services-choices'>
              <span className='services-block'>
                <span className='services-icon-block'>
                  <Terminal fontSize={24}/>
                </span>
                <span className='services-title'>
                  Software
                </span>
                <span></span>
              </span>
            </span>
          </span>
          <span sm={6} md={3}>
            <span className='services-choices'>
              <span className='services-block'>
                <span className='services-icon-block'>
                  <Water fontSize={24}/>
                </span>
                <span className='services-title'>
                  Water Damage
                </span>
                <span></span>
              </span>
            </span>
          </span>
          <span sm={6} md={3}>
            <span className='services-choices'>
              <span className='services-block'>
                <span className='services-icon-block'>
                  <Printer fontSize={24}/>
                </span>
                <span className='services-title'>
                  Printer Configuration
                </span>
                <span></span>
              </span>
            </span>
          </span>
          <span sm={6} md={3}>
            <span className='services-choices'>
              <span className='services-block'>
                <span className='services-icon-block'>
                  <WindowStack fontSize={24}/>
                </span>
                <span className='services-title'>
                  OS Repair / Upgrade
                </span>
                <span></span>
              </span>
            </span>
          </span>
          <span sm={6} md={3}>
            <span className='services-choices'>
              <span className='services-block'>
                <span className='services-icon-block'>
                  <Speedometer fontSize={24}/>
                </span>
                <span className='services-title'>
                  PC Tune-UP
                </span>
                <span></span>
              </span>
            </span>
          </span>
        </animated.span>
      </Row>
      <Row>
        <animated.span ref={contactText} className='services-title' style={textSlideDownAnim}>
          <animated.h4>Can't find the service you need?</animated.h4>
          <span className='left-side-buttons'>
            <a href="tel:586-268-6236">
              <button className='button-style button-style-red call-us'>Call Us</button>
            </a>
          </span>
        </animated.span>
      </Row>
    </Container>
  )
}
