import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import './Home.css'
import StyledImage from 'components/utils/StyledImage'
import img2 from 'assets/images/2.jpg'
import img3 from 'assets/images/3.jpg'
import img7 from 'assets/images/7.jpg'
import { useSpring, animated } from 'react-spring'

export default function Home() {
  const textAnim = useSpring({ 
    to: { opacity: 1, transform: `translateX(0px)` }, 
    from: { opacity: 0, transform: `translateX(-50px)` },
    delay: 100
  })
  return (
    <Container>
      <Row>
        <Col>
          <animated.span className='left-side-text' style={textAnim}>
            <h3>Need a repair or custom build?</h3>
            <h2>We Can <span style={{color:"var(--color-red)"}}>Help</span>!</h2>
            <h4>
            We retain two decades worth of experience in repairing and custom building computers that go above and beyond our customer's expectation.
            </h4>
            <span className='left-side-buttons'>
              <a href="tel:586-268-6236">
                <button className='button-style button-style-red call-us'>Call Us</button>
              </a>
              <a href="#services">
                <button className='button-style button-style-blue what-we-service'>What We Service</button>
              </a>
            </span>
          </animated.span>
        </Col>
        <Col>
          <div className='styled-images-container'>
            <StyledImage imgSrc={img2} classTarget='img1'/>
            <StyledImage imgSrc={img7} classTarget='img2'/>
            <StyledImage imgSrc={img3} classTarget='img3'/>
          </div>
        </Col>
      </Row>
    </Container>
  )
}
