import React, { useEffect, useRef } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { Facebook, House, HouseFill, Instagram, People, PeopleFill, Phone, Telephone, TelephoneFill } from 'react-bootstrap-icons'
import './Footer.css'


export default function Footer() {
  const date = new Date();
  const yearDate = date.getFullYear()

  
  return (
    <Container fluid className='footer-background p-0'>
      <Container>
        <Row className='footer-container'>
          <Col sm={12} md={{span: 4, offset: 1}}>
            <span className='footer-first-col'>
              <span className='footer-logo'>STARTEC COMPUTERS</span>
              <span className='footer-address'>
                <span className='address-icon'>
                  <HouseFill fontSize={18}></HouseFill>
                </span>
                5826 15 Mile Rd<br/>
                Sterling Heights, MI 48310
              </span>
              <span className='footer-number'>
                <span className='phone-icon'>
                  <TelephoneFill fontSize={18}></TelephoneFill>
                </span>
                (586) 268-6236
              </span>
              <span className='footer-socials'>
                <a href="https://www.instagram.com/starteccomputersmi/" target="_blank">
                  <span className='footer-social'>
                    <Instagram fontSize={20}></Instagram>
                  </span>
                </a>
                <a href="https://www.facebook.com/StartecComputers/" target="_blank">
                  <span className='footer-social'>
                    <Facebook fontSize={20}></Facebook>
                  </span>
                </a>
                <a href="https://www.google.com/search?ei=qEz8WsmME8m2jwPz84fIBw&q=Startec+Computers&oq=Startec+Computers&gs_l=psy-ab.3..35i39k1l2j0j0i22i30k1l2j0i22i10i30k1j38.7866.9914.0.16921.17.14.0.0.0.0.299.2083.0j8j3.11.0....0...1c.1.64.psy-ab..6.11.2081...0i131k1j0i20i264k1j0i20i263k1j0i10k1.0.KfN1Rir9PhE#lrd=0x8824db11864ac19f:0x4f212bcd53b492d0,1,,," target="_blank">
                  <span className='footer-social'>
                    <PeopleFill fontSize={20}></PeopleFill>
                  </span>
                </a>
              </span>
            </span>
          </Col>
          <Col sm={12} md={{span: 3}}>
            <span className='footer-second-col'>
              <span className='footer-links-title'>
                QUICK LINKS
              </span>
              <span className='footer-links'>
                <ul>
                  <li><a href="#home">Home</a></li>
                  <li><a href="#why-choose-us">About Us</a></li>
                  <li><a href="#services">Services</a></li>
                  <li><a href="#testimonials">Reviews</a></li>
                  {/* <li><a href="#home">Contact Us</a></li> */}
                </ul>
              </span>
            </span>
          </Col>
          <Col sm={12} md={{span: 4}}>
            <span className='footer-third-col'>
              <span className='footer-hours-title'>
                BUSINESS HOURS
              </span>
              <span className='footer-hours'>
                <ul>
                  <li><b>Monday - Friday</b> : 10 AM - 5 PM</li>
                  <li><b>Saturday</b> : 10 AM - 4 PM</li>
                  <li><b>Sunday</b> : Closed</li>
                </ul>
              </span>
            </span>
          </Col>
        </Row>
      </Container>
      {/* <Container fluid className='footer-date-container'> */}
        <Row className='footer-date-container'>
          <span className='footer-date'>
            {yearDate} Startec Computers. All Rights Reserved.
          </span>
        </Row>
      {/* </Container> */}
    </Container>
  )
}
