import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export const ScrollHandler = ({ children}) => {

    const { pathname, hash } = useLocation()

    const handleScroll = () => {

        // const element = document.getElementById(hash.replace("#", ""));

        // setTimeout(() => {
        //   let a = element.offsetHeight + 50
        //   window.scrollTo({
        //       behavior: element ? "smooth" : "auto",
        //       top: a ? element.offsetTop : 0
        //   });
        // }, 100);
    };

    useEffect(() => {
        handleScroll()
    }, [pathname, hash])

    return children
}