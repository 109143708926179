import './App.css';
import 'styles/vars.css'
import Header from './components/Header/Header';
import Home from './views/Home/Home';
import WhyUs from './views/WhyUs/WhyUs';
import VideoRow from './views/VideoRow/VideoRow';
import Reviews from './views/Reviews/Reviews';
import Services from './views/Services/Services';
import Footer from './views/Footer/Footer';
import {BrowserRouter as Router, Routes, Route, Link} from 'react-router-dom';
import { ScrollHandler } from 'components/utils/ScrollHandler';
import React from 'react';

function App() {
  return (
    <div>
      <ScrollHandler />
      <div className="App">
        {/* <Routes>
          <Route exact path='/' element={<Home />} />
          <Route exact path='/' element={<WhyUs />} />
        </Routes> */}
        <span id="home"></span>
        <Header/>
        <div className='margin-sep'></div>
        <Home />
        <div className='margin-sep' id="why-choose-us"></div>
        <WhyUs/>
        <div className='margin-sep' id="services"></div>
        <Services />
        <div className='margin-sep' id='testimonials'></div>
        <Reviews/>
        <Footer />
      </div>
    </div>
  );
}

export default App;
