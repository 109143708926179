import React, { useEffect, useRef, useState } from 'react'
import { Container, Row, Col, Button } from 'react-bootstrap'
import './Reviews.css'
import ReviewBlock from 'components/utils/ReviewBlock'
import { useSpring, animated } from 'react-spring'
import { ArrowRepeat } from 'react-bootstrap-icons'
import { useInView } from 'react-intersection-observer'
import {GoogleReviews} from './google-reviews'

export default function Reviews() {

  const { ref: titleRef, inView: isTitleVisible } = useInView({
    threshold: 1,
    triggerOnce: true
  });

  const fadeInAnim = useSpring({ 
    to: { opacity: isTitleVisible ? 1 : 0 }, 
    from: { opacity: 0},
    delay: 100
  })

  const textSlideDownAnim = useSpring({ 
    to: { opacity: isTitleVisible ? 1 : 0, transform: isTitleVisible ? 'translateY(0px)' : 'translateY(-50px)'}, 
    from: { opacity: 0, transform: 'translateY(-50px)'},
    delay: 200
  })

  const textSlideDownAnim2 = useSpring({ 
    to: { opacity: isTitleVisible ? 1 : 0, transform: isTitleVisible ? 'translateY(0px)' : 'translateY(-50px)'}, 
    from: { opacity: 0},
    delay: 300
  })

  const textSlideDownAnim3 = useSpring({ 
    to: { opacity: isTitleVisible ? 1 : 0, transform: isTitleVisible ? 'translateY(0px)' : 'translateY(-50px)'}, 
    from: { opacity: 0},
    delay: 400
  })

  let offsetValue = window.scrollY / document.body.scrollHeight
  const [backgroundStyle, setBackgroundStyle] = useState({
    background: 'linear-gradient(' + offsetValue * 360 + 'deg, #f054549e, #287ed49e)'
  })

  const reviews = []
  GoogleReviews.forEach((review) => {
    reviews.push(review)
  })

  let [a, setA] = useState(0)
  let [b, setB] = useState(1)
  let [c, setC] = useState(2)

  const reloadReviews = () => {
    let randomArr = Array(reviews.length - 1).fill().map((_, i) => i + 1)
    randomArr.sort(() => Math.random() - 0.5)
    setA(randomArr[0])
    setB(randomArr[1])
    setC(randomArr[2])
  }

  let handleBackground = () => {
    let offsetValue = window.scrollY / document.body.scrollHeight
    setBackgroundStyle({
      background: 'linear-gradient(' + offsetValue * 1500 + 'deg, #f054549e, #287ed49e)'
    })
  }

  useEffect(() => {
    window.addEventListener('scroll', handleBackground);
  },[])

  
  return (
    <Container fluid className='reviews-title-container p-0'>
      <animated.span className='row-titles p-0' style={fadeInAnim}>
        <animated.h2>CUSTOMER FEEDBACK</animated.h2>
        <animated.h5>Checkout what our customers say about our services!</animated.h5>
      </animated.span>
      <span ref={titleRef}></span>
      <Container fluid id="reviews-container" className="p-0" style={backgroundStyle}>
        <Container>
          <Row>
              <ReviewBlock 
                name={reviews[a].name}
                statement={reviews[a].statement}
                url={reviews[a].url} key={a} textSlideDownAnim={textSlideDownAnim}></ReviewBlock>

              <ReviewBlock 
              name={reviews[b].name}
              statement={reviews[b].statement}
              url={reviews[b].url} key={b} textSlideDownAnim={textSlideDownAnim2}></ReviewBlock>

              <ReviewBlock 
              name={reviews[c].name}
              statement={reviews[c].statement}
              url={reviews[c].url} key={c} textSlideDownAnim={textSlideDownAnim3}></ReviewBlock>

          </Row>
          <Row>
            <Col md={{span: 4, offset: 4}} className='p-0'>
              <animated.button className='button-style more-reviews'
              style={fadeInAnim}
              onClick={reloadReviews}>More Reviews 
                &nbsp;<ArrowRepeat fontSize={20}/>
              </animated.button>
            </Col>
          </Row>
        </Container>
      </Container>
    </Container>
  )
}
