import React from 'react'
import './StyledImage.css'
import { useSpring, animated } from 'react-spring'

export default function StyledImage(props) {
  const imageAnim = useSpring({ 
    to: { opacity: 1, transform: `translateX(0px)` }, 
    from: { opacity: 0, transform: `translateX(50px)` },
    delay: 300 * parseInt(props.classTarget.match(/[0-9]/g)[0])
  })
  return (
    <animated.div className={`styled-image-block ${props.classTarget}`} style={imageAnim}>
      <span style={{background: 'url(' + props.imgSrc + ')', }}></span>
    </animated.div>
  )
}
